<template>
  <el-row style="margin-top: 20px;">
    <el-checkbox-group
      v-model="selectedOption"
      :min="1"
      style="display: grid; width: 100%"
      :style="{'grid-template-columns': 'repeat('+ FILE_TYPE_OPTIONS.length +', 1fr)'}"
      @change="emitData"
    >
      <el-checkbox
        v-for="option in FILE_TYPE_OPTIONS"
        :key="option.value"
        border
        size="large"
        :label="option.value"
      >
        {{ translateText(option.label) }}
      </el-checkbox>
    </el-checkbox-group>
  </el-row>
  <el-row style="margin-top: 20px;">
    <el-col>
      <p>{{ `${translateText('gaz.interview.file_question.max_size')}:` }}</p>
      <el-input-number
        v-model="state.maxSize"
        :class="[{'is-error': errors.maxSize && showErrors}]"
        :disabled="onlyMeta"
        @update:modelValue="emitData"
      />
    </el-col>
  </el-row>
  <el-row style="margin-top: 20px;">
    <el-col>
      <p>{{ translateText('gaz.interview.file_question.warning_1') }}</p>
      <p>{{ translateText('gaz.interview.file_question.warning_2') }}</p>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import { IFileQuestion } from 'features/settings/interviews/interfaces';
import { FILE_TYPE, FILE_TYPE_OPTIONS } from 'features/settings/interviews/constants';
import { translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'UploadQuestion',
  props: {
    data: {
      type: Object as PropType<IFileQuestion>,
      required: true,
    },
    showErrors: Boolean,
    onlyMeta: Boolean,
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const { customT } = useTranslate();

    const state: Partial<IFileQuestion> = reactive({
      maxSize: props.data.maxSize ?? 1,
      photoCamera: props.data.photoCamera ?? true,
      photoGallery: props.data.photoGallery ?? false,
      document: props.data.document ?? false,
      audio: props.data.audio ?? true,
      video: props.data.video ?? true,
    });
    const errors: {
      maxSize: boolean
    } = {
      maxSize: false,
    };

    const selectedOption = ref([]);

    const initializeSelectedOption = () => {
      selectedOption.value = Object.entries(state)
        .filter(([key, value]) => key !== FILE_TYPE.VIDEO && value === true)
        .map(([key]) => key);
    };
    /**
     *
     */
    watch(() => props.data, () => {
      state.maxSize = props.data.maxSize ?? 1;
      state.photoCamera = props.data.photoCamera ?? true;
      state.photoGallery = props.data.photoGallery ?? true;
      state.document = props.data.document ?? true;
    });
    /**
     *
     */
    const emitData = () => {
      errors.maxSize = state.maxSize === 0;

      state.photoCamera = selectedOption.value.includes(FILE_TYPE.CAMERA);
      state.photoGallery = selectedOption.value.includes(FILE_TYPE.PHOTO);
      state.document = selectedOption.value.includes(FILE_TYPE.DOCUMENT);

      emit('update:data', { data: state, errors });
    };
    /**
     *
     */
    onMounted(() => {
      initializeSelectedOption();
      setTimeout(() => {
        emitData();
      });
    });

    return {
      state,
      errors,
      selectedOption,

      FILE_TYPE_OPTIONS,
      emitData,
      translateText: (code: string) => customT(translate(code)),
    };
  },
});
</script>
